import { useParams } from "react-router-dom";
import "./companies.scss";

const CompanyDetails = () => {
  const { id } = useParams();

  const handleKYBClick = () => {
    window.open("https://kyb.stage-mudax.xyz/connections/web-wallet?company_id=C768adfdb16f740c6aaa4f89270e6e711", "_blank");
  };

  // Mocked company data (replace with an API call for dynamic data)
  const company = {
    id,
    name: "",
    country: "",
    city: "London",
    address: "Chesterfield avenue, 193G/5",
    zipCode: "SE17 9SE",
    team: [

    ],
  };

  return (
    <div className="company_details_page">
      <div className="company_details_header">
        <h2>{company.name}</h2>
        <button onClick={handleKYBClick}>Company KYB</button>
      </div>
      <form className="company_details_form">
        <div className="form_group">
          <label>Company Name</label>
          <input type="text" value={company.name} disabled />
        </div>
        <div className="form_group">
          <label>Company Number</label>
          <input type="text" value={company.country} disabled />
        </div>

        <div className="form_actions">
          <button type="button">Cancel</button>
          <button type="button">Save</button>
        </div>
      </form>
      <h3>Team</h3>
      {company.team.length === 0 ? (
        <div className="no_data_message">No team members to display</div>
      ) : (
        <table className="team_table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {company.team.map((member, index) => (
              <tr key={index}>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.role}</td>
                <td className={member.status.toLowerCase()}>{member.status}</td>
                <td>
                  <button>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <button className="delete_account_button">Delete company account</button>
    </div>
  );
};

export default CompanyDetails;