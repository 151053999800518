/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from "../../providers/AuthProvider";
import "./paymentMethods.scss";
import Pagination from './pagination';
import AddPaymentMethod from './addPaymentMethod';
import { Loader2, FileText, PlusCircle } from "lucide-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentMethods = () => {
  const { authTokens } = useAuth();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterState, setFilterState] = useState("All");
  const [filterCurrency, setFilterCurrency] = useState("All");
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentMethodsPerPage] = useState(10);
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await api.getMudaPaymentMethods({ token: authTokens.IdToken });
        console.log("Response from API: ", response.data.items)
        if (Array.isArray(response.data.items)) {
          setPaymentMethods(response.data.items);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentMethods();
  }, [authTokens]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e) => {
    setFilterState(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setFilterCurrency(e.target.value);
  };

  const filteredPaymentMethods = paymentMethods.filter((method) => {
    const matchesSearchQuery = [
      method.accountName?.toLowerCase(),
      method.bankName?.toLowerCase(),
      method.accountNumber?.toLowerCase(),
      method.currency?.toLowerCase(),
      method.type?.toLowerCase(),
      new Date(method.createdAt).toLocaleDateString().toLowerCase(),
    ].some((field) => field && field.includes(searchQuery));
  
    const matchesFilterState = filterState === "All" || method.class === filterState;
    const matchesFilterCurrency = filterCurrency === "All" || method.currency === filterCurrency;
  
    return matchesSearchQuery && matchesFilterState && matchesFilterCurrency;
  });

  // Sort payment methods by date (most recent first)
  const sortedPaymentMethods = filteredPaymentMethods.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Get current payment methods
  const indexOfLastPaymentMethod = currentPage * paymentMethodsPerPage;
  const indexOfFirstPaymentMethod = indexOfLastPaymentMethod - paymentMethodsPerPage;
  const currentPaymentMethods = sortedPaymentMethods.slice(indexOfFirstPaymentMethod, indexOfLastPaymentMethod);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openPaymentMethodModal = (method) => {
    setSelectedPaymentMethod(method);
    setShowPaymentMethodModal(true);
  };

  const closePaymentMethodModal = () => {
    setShowPaymentMethodModal(false);
    setSelectedPaymentMethod(null);
  };

  const openAddPaymentMethodModal = () => {
    setShowAddPaymentMethodModal(true);
  };

  const closeAddPaymentMethodModal = () => {
    setShowAddPaymentMethodModal(false);
  };

  const refreshPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const response = await api.getMudaPaymentMethods({ token: authTokens.IdToken });
      if (Array.isArray(response.data.items)) {
        setPaymentMethods(response.data.items);
      } else {
        console.error("Expected an array but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccess = (message) => {
    toast.success(message);
    refreshPaymentMethods();
  };

  return (
    <div className="payment_methods_page">
      <div className="heading">
        <div className="title">Payment Methods</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for payment methods..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="filter_currency">
          <select value={filterCurrency} onChange={handleCurrencyChange}>
            <option value="All">All Currencies</option>
            <option value="USD">USD</option>
            <option value="NGN">NGN</option>
            <option value="UGX">UGX</option>
            {/* Add more currencies as needed */}
          </select>
        </div>
        <div className="filter_state">
          <select value={filterState} onChange={handleFilterChange}>
            <option value="NORMAL">Normal</option>
            <option value="PREMIUM">Premium</option>
            <option value="All">All</option>
          </select>
        </div>
        <button onClick={openAddPaymentMethodModal} className="add_payment_method_button">
          <PlusCircle size={16} /> Add Payment Method
        </button>
      </div>
      <div className="payment_methods_table_container">
        {isLoading ? (
          <Loader2 className="loading_spinner" />
        ) : sortedPaymentMethods.length === 0 ? (
          <div className="no_data_message">No payment methods to display</div>
        ) : (
          <>
            <table className="payment_methods_table">
              <thead>
                <tr>
                  <th>Account Name</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Class</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPaymentMethods.map((method) => (
                  <tr key={method.id}>
                    <td>{method.accountName}</td>
                    <td>{method.bankName}</td>
                    <td>{method.accountNumber}</td>
                    <td>{method.currency}</td>
                    <td>{method.type}</td>
                    <td>{new Date(method.createdAt).toLocaleDateString()}</td>
                    <td>{method.class}</td>
                    <td>
                      <div className="payment_method_controls">
                        <FileText
                          className="view_payment_method user_control"
                          onClick={() => openPaymentMethodModal(method)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              transactionsPerPage={paymentMethodsPerPage}
              totalTransactions={sortedPaymentMethods.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
      {showPaymentMethodModal && (
        <div className="payment_method_modal">
          <div className="payment_method_modal_content">
            <h2>Payment Method Details</h2>
            <div className="payment_method_detail">
              <span className="payment_method_label">Account Name:</span>
              <span className="payment_method_value">{selectedPaymentMethod.accountName}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Bank Name:</span>
              <span className="payment_method_value">{selectedPaymentMethod.bankName}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Account Number:</span>
              <span className="payment_method_value">{selectedPaymentMethod.accountNumber}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Currency:</span>
              <span className="payment_method_value">{selectedPaymentMethod.currency}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Type:</span>
              <span className="payment_method_value">{selectedPaymentMethod.type}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Date:</span>
              <span className="payment_method_value">{new Date(selectedPaymentMethod.createdAt).toLocaleDateString()}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Class:</span>
              <span className="payment_method_value">{selectedPaymentMethod.class}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Swift Code:</span>
              <span className="payment_method_value">{selectedPaymentMethod.swiftCode}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Address:</span>
              <span className="payment_method_value">{selectedPaymentMethod.address}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">City:</span>
              <span className="payment_method_value">{selectedPaymentMethod.city}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Region:</span>
              <span className="payment_method_value">{selectedPaymentMethod.region}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Country:</span>
              <span className="payment_method_value">{selectedPaymentMethod.country}</span>
            </div>
            <div className="payment_method_detail">
              <span className="payment_method_label">Building Number:</span>
              <span className="payment_method_value">{selectedPaymentMethod.buildingNumber}</span>
            </div>
            <div className="modal_buttons centered_buttons">
              <button onClick={closePaymentMethodModal}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showAddPaymentMethodModal && (
        <AddPaymentMethod
          closeModal={closeAddPaymentMethodModal}
          onSuccess={handleSuccess}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default PaymentMethods;