import { useEffect, useState } from "react";
import api from "../../api";
import { useAuth } from "../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import "./companies.scss";
import { useNavigate } from "react-router-dom";

const Companies = () => {
  const { authTokens } = useAuth();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedCompany, setEditedCompany] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch companies data from the API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.getUsers({ token: authTokens.IdToken });
        console.log("List of companies: ", response);
        if (Array.isArray(response.data.items)) {
          const companyItems = response.data.items.filter(item => item.userType === "COMPANY");
          setCompanies(companyItems);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, [authTokens]);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedCompany({ ...companies[index] });
  };

  const handleSaveClick = () => {
    const updatedCompanies = [...companies];
    updatedCompanies[editingIndex] = editedCompany;
    setCompanies(updatedCompanies);
    setEditingIndex(null);
  };

  const handleCancelClick = () => {
    setEditingIndex(null);
    setEditedCompany({});
  };

  const handleInputChange = (field, value) => {
    setEditedCompany({ ...editedCompany, [field]: value });
  };

  const handleRowClick = (id) => {
    navigate(`/companies/${id}`);
  };

  const filteredCompanies = companies.filter(company =>
    company.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.companyRegNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.domain.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className="company_list_page">
      <div className="heading">
        <div className="title">Companies</div>
        <div className="search_add_container">
          <input
            type="text"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search_input"
          />
          <button
            className="add_company_button"
            onClick={() => navigate("/company/create")}
          >
            Add Company
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="loader-container">
          <Loader2 className="loader" />
        </div>
      ) : (
        <div className="users_table_container">
          <table className="users_table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Company Name</th>
                <th>Company Reg. Number</th>
                <th>Domain</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCompanies.map((company, index) => (
                <tr key={index} onClick={() => handleRowClick(company.id)}>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={editedCompany.firstName || ""}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                    ) : (
                      company.firstName || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={editedCompany.lastName || ""}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                    ) : (
                      company.lastName || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="email"
                        value={editedCompany.username || ""}
                        onChange={(e) =>
                          handleInputChange("username", e.target.value)
                        }
                      />
                    ) : (
                      company.username || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={editedCompany.companyName || ""}
                        onChange={(e) =>
                          handleInputChange("companyName", e.target.value)
                        }
                      />
                    ) : (
                      company.companyName || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={editedCompany.companyRegNumber || ""}
                        onChange={(e) =>
                          handleInputChange("companyRegNumber", e.target.value)
                        }
                      />
                    ) : (
                      company.companyRegNumber || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={editedCompany.domain || ""}
                        onChange={(e) =>
                          handleInputChange("domain", e.target.value)
                        }
                      />
                    ) : (
                      company.domain || "N/A"
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <>
                        <button
                          className="approve_button"
                          onClick={handleSaveClick}
                        >
                          Save
                        </button>
                        <button
                          className="cancel_btn"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="approve_button"
                        onClick={() => handleEditClick(index)}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Companies;