/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from "../../providers/AuthProvider";
import "./transactions.scss";
import Pagination from './pagination';
import { Loader2, FileText } from "lucide-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatMoney } from '../../common/formatAmount';

const Transactions = () => {
  const { authTokens } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterState, setFilterState] = useState("All");
  const [filterCurrency, setFilterCurrency] = useState("All");
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(10);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await api.getTransactions({ token: authTokens.IdToken });
        console.log("Response from API: ", response.data.items)
        if (Array.isArray(response.data.items)) {
          setTransactions(response.data.items);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [authTokens]);

  const getCountryFromCurrency = (currency) => {
    const currencyCountryMap = {
      NGN: "Nigeria",
      UGX: "Uganda",
      USD: "United States",
      // Add more mappings as needed
    };
    return currencyCountryMap[currency] || "Unknown";
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e) => {
    setFilterState(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setFilterCurrency(e.target.value);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearchQuery = [
      getCountryFromCurrency(transaction.walletCurrency).toLowerCase(),
      transaction.walletCurrency.toLowerCase(),
      transaction.paymentReference.toLowerCase(),
      new Date(transaction.createdOn).toLocaleDateString().toLowerCase(),
      transaction.walletTxnAmount.toString(),
      transaction.id.toString(),
      transaction.status.toLowerCase(),
      transaction.paymentType.toLowerCase(),
    ].some((field) => field.includes(searchQuery));
  
    const matchesFilterState = filterState === "All" || transaction.status === filterState;
    const matchesFilterCurrency = filterCurrency === "All" || transaction.walletCurrency === filterCurrency;
  
    return matchesSearchQuery && matchesFilterState && matchesFilterCurrency;
  });

  // Sort transactions by date (most recent first)
  const sortedTransactions = filteredTransactions.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  // Get current transactions
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = sortedTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openTransactionModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowTransactionModal(true);
  };

  const closeTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransaction(null);
  };

  return (
    <div className="transactions_page">
      <div className="heading">
        <div className="title">Transactions</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for transactions..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="filter_currency">
          <select value={filterCurrency} onChange={handleCurrencyChange}>
            <option value="All">All Currencies</option>
            <option value="USD">USD</option>
            <option value="NGN">NGN</option>
            <option value="UGX">UGX</option>
            {/* Add more currencies as needed */}
          </select>
        </div>
        <div className="filter_state">
          <select value={filterState} onChange={handleFilterChange}>
            <option value="PENDING">Pending</option>
            <option value="COMPLETED">Completed</option>
            <option value="APPROVED">Approved</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div className="transactions_table_container">
        {isLoading ? (
          <Loader2 className="loading_spinner" />
        ) : sortedTransactions.length === 0 ? (
          <div className="no_data_message">No transactions to display</div>
        ) : (
          <>
            <table className="transactions_table">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Reference</th>
                  <th>Transaction type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Approvers</th>
                  <th>Status</th>
                  <th>PayType</th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{getCountryFromCurrency(transaction.walletCurrency)}</td>
                    <td>{transaction.walletCurrency}</td>
                    <td>{transaction.paymentReference}</td>
                    <td>{transaction.walletTxnType}</td>
                    <td>{new Date(transaction.createdOn).toLocaleDateString()}</td>
                    <td>{formatMoney(transaction.walletTxnAmount, transaction.walletCurrency)}</td>
                    <td>{transaction.numberOfApprovers}</td>
                    <td>{transaction.status}</td>
                    <td>{transaction.paymentType}</td>
                    <td>
                      <div className="transaction_controls">
                        <FileText
                          className="view_transaction user_control"
                          onClick={() => openTransactionModal(transaction)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              transactionsPerPage={transactionsPerPage}
              totalTransactions={sortedTransactions.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
      {showTransactionModal && (
        <div className="transaction_modal">
          <div className="transaction_modal_content">
            <h2>Transaction Details</h2>
            <div className="transaction_detail">
              <span className="transaction_label">Date:</span>
              <span className="transaction_value">{selectedTransaction.createdOn}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Transaction Type:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnType}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Amount:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnAmount}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Status:</span>
              <span className="transaction_value">{selectedTransaction.status}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Account Number:</span>
              <span className="transaction_value">{selectedTransaction.profileId}</span>
            </div>
            <div className="modal_buttons centered_buttons">
              <button onClick={closeTransactionModal}>Close</button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Transactions;