import _ from "lodash";
export const formatAmount = (amount) => {
    amount = parseFloat(amount);
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    const options = { minimumFractionDigits: 0, maximumFractionDigits: 8 };
    const result = `${amount.toLocaleString(locale, options)}`;
    return !_.isNaN(amount) ? result : "";
};

export const formatMoney = (amount, currency) => {
    try {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
      }).format(amount);
    // eslint-disable-next-line no-unused-vars
    } catch (e) {
      return `${currency} ${amount.toFixed(2)}`;
    }
  };
