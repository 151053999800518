/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';

const Pagination = ({ transactionsPerPage, totalTransactions, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination">
      <ul className="pagination_list">
        <li className={`pagination_item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a onClick={() => currentPage > 1 && paginate(currentPage - 1)} href="#!" className="pagination_link">
            &laquo; Previous
          </a>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={`pagination_item ${number === currentPage ? 'active' : ''}`}>
            <a onClick={() => paginate(number)} href="#!" className="pagination_link">
              {number}
            </a>
          </li>
        ))}
        <li className={`pagination_item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
          <a onClick={() => currentPage < pageNumbers.length && paginate(currentPage + 1)} href="#!" className="pagination_link">
            Next &raquo;
          </a>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  transactionsPerPage: PropTypes.number.isRequired,
  totalTransactions: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;