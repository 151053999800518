/* eslint-disable no-unused-vars */
import "./users.scss";
import { Pencil, Check, X, Loader2, Search, PlusCircle } from "lucide-react";
import { useState, useEffect } from "react";
import api from "../../api"; // Import the API module
import { useAuth } from "../../providers/AuthProvider"; // Import the AuthProvider
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const AdminUsers = () => {
    const { authTokens } = useAuth(); // Get auth tokens from AuthProvider
    const [users, setUsers] = useState([]);
    const [editedUsers, setEditedUsers] = useState({});
    const [isSavingUser, setIsSavingUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showKycModal, setShowKycModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        role: "maker",
    });

    useEffect(() => {
        const fetchAdminUsers = async () => {
            setIsLoading(true);
            try {
                const response = await api.getAdminUsers({ token: authTokens.IdToken });
                setUsers(response.data);
                toast.success("Admin users fetched successfully!");
            } catch (error) {
                console.error("Error fetching admin users:", error);
                toast.error("Error fetching admin users:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAdminUsers();
    }, [authTokens]);

    useEffect(() => {
        const newEditedUsers = {};
        users.forEach((user) => {
            newEditedUsers[user.id] = { ...user, editing: false, saving: false };
        });
        setEditedUsers(newEditedUsers);
    }, [users]);

    const editUser = (user) => {
        const editedUser = editedUsers[user.id];
        editedUser.editing = true;
        setEditedUsers({
            ...editedUsers,
            [user.id]: editedUser,
        });
    };

    const editingUser = (user) => {
        return editedUsers[user.id] && editedUsers[user.id].editing;
    };

    const savingUser = (user) => {
        return editedUsers[user.id] && editedUsers[user.id].saving;
    };

    const cancelEditUser = (user) => {
        const oldUser = users.find((u) => u.id === user.id);
        setEditedUsers({
            ...editedUsers,
            [user.id]: { ...oldUser, editing: false, saving: false },
        });
    };

    const updateUser = (user) => {
        const editedUser = editedUsers[user.id];
        setIsSavingUser(true);
        // Simulate saving user
        setTimeout(() => {
            setUsers(users.map((u) => (u.id === user.id ? editedUser : u)));
            setIsSavingUser(false);
            setEditedUsers({
                ...editedUsers,
                [user.id]: { ...editedUser, editing: false, saving: false },
            });
            toast.success("User updated successfully!");
        }, 1000);
    };

    const handleInputChange = (user, field, value) => {
        const editedUser = editedUsers[user.id];
        editedUser[field] = value;
        setEditedUsers({
            ...editedUsers,
            [user.id]: editedUser,
        });
    };

    const handleNewUserInputChange = (field, value) => {
        setNewUser({
            ...newUser,
            [field]: value,
        });
    };

    const addUser = () => {
        setShowAddUserModal(true);
    };

    const saveNewUser = async () => {
        const [firstName, lastName] = newUser.name.split(" ");
        const newUserToAdd = {
            firstName,
            lastName,
            email: newUser.email,
            phonenumber: "+256787729728", // Add the phone number here
        };

        try {
            await api.addAdminUser({ token: authTokens.IdToken, adminUserData: newUserToAdd });
            setUsers([...users, { ...newUserToAdd, id: users.length + 1, role: newUser.role, registrationDate: new Date().toISOString().split("T")[0], rights: "Not Approver", editing: false, saving: false }]);
            setShowAddUserModal(false);
            setNewUser({
                name: "",
                email: "",
                role: "maker",
            });
            toast.success("Admin user added successfully!");
        } catch (error) {
            console.error("Error adding admin user:", error);
            toast.error("Error adding admin user.");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter((user) => {
        return (
            user.name?.toLowerCase().includes(searchQuery) ||
            user.email?.toLowerCase().includes(searchQuery) ||
            user.role?.toLowerCase().includes(searchQuery) ||
            user.registrationDate?.includes(searchQuery)
        );
    });

    const openKycModal = (user) => {
        setSelectedUser(user);
        setShowKycModal(true);
    };

    const closeKycModal = () => {
        setShowKycModal(false);
        setSelectedUser(null);
    };

    const handleKycUpload = () => {
        // Handle KYC document upload
        console.log("KYC document uploaded for user:", selectedUser);
        closeKycModal();
    };

    const makeUserApprover = (user) => {
        setSelectedUser(user);
        setShowConfirmDialog(true);
    };

    const confirmMakeUserApprover = () => {
        setIsApproving(true);
        setTimeout(() => {
            const updatedUser = { ...selectedUser, rights: "Approver" };
            setUsers(users.map((u) => (u.id === selectedUser.id ? updatedUser : u)));
            setShowConfirmDialog(false);
            setSelectedUser(null);
            setIsApproving(false);
            toast.success("User made approver successfully!");
        }, 3000);
    };

    const cancelMakeUserApprover = () => {
        setShowConfirmDialog(false);
        setSelectedUser(null);
    };

    return (
        <div className="client_users_page">
            <div className="heading">
                <div className="title">Admin Users</div>
            </div>
            <div className="search_inputs">
                <div className="search_input">
                    <div className="input search_query_container">
                        <input
                            className="search_query"
                            type="text"
                            placeholder="Search for users..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <Search className="icon" />
                    </div>
                </div>
                <button onClick={addUser} className="add_user_button">
                    Add Admin
                </button>
            </div>
            {isLoading ? (
                <div className="loader-container">
                    <Loader2 className="loader" />
                </div>
            ) : (
                <div className="users_table_container">
                    <table className="users_table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Registration Date</th>
                                <th>Rights</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>
                                            {!editingUser(user) ? (
                                                user.name
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="edit_input"
                                                    value={editedUsers[user.id].name}
                                                    onChange={(e) =>
                                                        handleInputChange(user, "name", e.target.value)
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {!editingUser(user) ? (
                                                user.email
                                            ) : (
                                                <input
                                                    type="email"
                                                    className="edit_input"
                                                    value={editedUsers[user.id].email}
                                                    onChange={(e) =>
                                                        handleInputChange(user, "email", e.target.value)
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {!editingUser(user) ? (
                                                user.role
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="edit_input"
                                                    value={editedUsers[user.id].role}
                                                    onChange={(e) =>
                                                        handleInputChange(user, "role", e.target.value)
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {!editingUser(user) ? (
                                                user.registrationDate
                                            ) : (
                                                <input
                                                    type="date"
                                                    className="edit_input"
                                                    value={editedUsers[user.id].registrationDate}
                                                    onChange={(e) =>
                                                        handleInputChange(user, "registrationDate", e.target.value)
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>{user.rights}</td>
                                        <td>
                                            <div className="user_controls">
                                                {!editingUser(user) && !savingUser(user) && (
                                                    <Pencil
                                                        className="edit user_control"
                                                        onClick={() => editUser(user)}
                                                    />
                                                )}
                                                {editingUser(user) && !savingUser(user) && (
                                                    <>
                                                        <Check
                                                            className="save_edit user_control"
                                                            onClick={() => updateUser(user)}
                                                        />
                                                        <X
                                                            className="cancel_edit user_control"
                                                            onClick={() => cancelEditUser(user)}
                                                        />
                                                    </>
                                                )}
                                                {savingUser(user) && (
                                                    <Loader2 className="saving_user_loader user_control" />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="no_data">
                                        No Admins
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {showConfirmDialog && (
                <div className="confirm_dialog">
                    <div className="confirm_dialog_content">
                        <h2>Confirm Action</h2>
                        <p>Are you sure you want to make {selectedUser.name} an approver?</p>
                        <div className="modal_buttons">
                            <button onClick={confirmMakeUserApprover} disabled={isApproving}>
                                {isApproving ? <Loader2 className="icon" /> : "Confirm"}
                            </button>
                            <button onClick={cancelMakeUserApprover} disabled={isApproving}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showKycModal && (
                <div className="kyc_modal">
                    <div className="kyc_modal_content">
                        <h2>Upload KYC Documents for {selectedUser.name}</h2>
                        <input type="file" onChange={handleKycUpload} />
                        <button onClick={closeKycModal}>Close</button>
                    </div>
                </div>
            )}
            {showAddUserModal && (
                <div className="add_user_modal">
                    <div className="add_user_modal_content">
                        <h2>Add Admin User</h2>
                        <div className="form_group">
                            <label>Name</label>
                            <input
                                type="text"
                                value={newUser.name}
                                onChange={(e) => handleNewUserInputChange("name", e.target.value)}
                                placeholder="Enter full name"
                            />
                        </div>
                        <div className="form_group">
                            <label>Email</label>
                            <input
                                type="email"
                                value={newUser.email}
                                onChange={(e) => handleNewUserInputChange("email", e.target.value)}
                                placeholder="Enter email address"
                            />
                        </div>
                        <div className="form_group">
                            <label>Role</label>
                            <select
                                value={newUser.role}
                                onChange={(e) => handleNewUserInputChange("role", e.target.value)}
                            >
                                <option value="maker">Maker</option>
                                <option value="checker">Checker</option>
                                <option value="master">Master</option>
                            </select>
                        </div>
                        <div className="modal_buttons">
                            <button onClick={saveNewUser}>Save</button>
                            <button onClick={() => setShowAddUserModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default AdminUsers;