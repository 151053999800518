/* eslint-disable react/jsx-no-undef */
import "./users.scss";
import { Loader2, Search } from "lucide-react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import api from "../../api";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

const ClientUsers = () => {
  const { authTokens } = useAuth();
  const [users, setUsers] = useState([]);
  const [editedUsers, setEditedUsers] = useState({});
  const navigate = useNavigate();
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showKycModal, setShowKycModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await api.getUsers({ token: authTokens.IdToken });
        if (Array.isArray(response.data.items)) {
          const userItems = response.data.items.filter(item => item.userType !== "COMPANY");
          setUsers(userItems);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [authTokens]);

  useEffect(() => {
    const newEditedUsers = {};
    users.forEach((user) => {
      newEditedUsers[user.id] = { ...user, editing: false, saving: false };
    });
    setEditedUsers(newEditedUsers);
  }, [users]);

  const editUser = (user) => {
    const editedUser = editedUsers[user.id];
    editedUser.editing = true;
    setEditedUsers({
      ...editedUsers,
      [user.id]: editedUser,
    });
  };

  const editingUser = (user) => {
    return editedUsers[user.id] && editedUsers[user.id].editing;
  };

  const savingUser = (user) => {
    return editedUsers[user.id] && editedUsers[user.id].saving;
  };

  const cancelEditUser = (user) => {
    const oldUser = users.find((u) => u.id === user.id);
    setEditedUsers({
      ...editedUsers,
      [user.id]: { ...oldUser, editing: false, saving: false },
    });
  };

  const updateUser = (user) => {
    const editedUser = editedUsers[user.id];
    setIsSavingUser(true);
    // Simulate saving user
    setTimeout(() => {
      setUsers(users.map((u) => (u.id === user.id ? editedUser : u)));
      setIsSavingUser(false);
      setEditedUsers({
        ...editedUsers,
        [user.id]: { ...editedUser, editing: false, saving: false },
      });
    }, 1000);
  };

  const handleInputChange = (user, field, value) => {
    const editedUser = editedUsers[user.id];
    editedUser[field] = value;
    setEditedUsers({
      ...editedUsers,
      [user.id]: editedUser,
    });
  };

  const addUser = () => {
    //setShowAddUserModal(true);
    navigate(`/companies/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredUsers = users.filter((user) => {
    return (
      (user.firstName && user.firstName.toLowerCase().includes(searchQuery)) ||
      (user.lastName && user.lastName.toLowerCase().includes(searchQuery)) ||
      (user.username && user.username.toLowerCase().includes(searchQuery)) ||
      (user.phonenumber && user.phonenumber.toLowerCase().includes(searchQuery))
    );
  });

  const openKycModal = (user) => {
    setSelectedUser(user);
    setShowKycModal(true);
  };

  const closeKycModal = () => {
    setShowKycModal(false);
    setSelectedUser(null);
  };

  const handleKycUpload = () => {
    // Handle KYC document upload
    console.log("KYC document uploaded for user:", selectedUser);
    closeKycModal();
  };

  const onAddUser = (data) => {
    const newUser = {
      id: users.length + 1,
      name: data.firstName + " " + data.lastName,
      email: data.email,
      role: data.role,
      phone: data.phone,
      address: data.address,
      registrationDate: new Date().toISOString().split("T")[0],
    };
    setUsers([...users, newUser]);
    setShowAddUserModal(false);
    reset();
  };

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
    reset();
  };

  return (
    <div className="client_users_page">
      <div className="heading">
        <div className="title">Client Users</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for users..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div>
        <button className="add_user_button"
          onClick={() => navigate("/company/create")}
        >
          Add User
        </button>
      </div>
      {isLoading ? (
        <div className="loader-container">
          <Loader2 className="loader" />
        </div>
      ) : (
        <div className="users_table_container">
          <table className="users_table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Created At</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>
                    {!editingUser(user) ? (
                      user.firstName
                    ) : (
                      <input
                        type="text"
                        value={editedUsers[user.id].firstName}
                        onChange={(e) =>
                          handleInputChange(user, "firstName", e.target.value)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {!editingUser(user) ? (
                      user.lastName
                    ) : (
                      <input
                        type="text"
                        value={editedUsers[user.id].lastName}
                        onChange={(e) =>
                          handleInputChange(user, "lastName", e.target.value)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {!editingUser(user) ? (
                      user.username
                    ) : (
                      <input
                        type="email"
                        value={editedUsers[user.id].username}
                        onChange={(e) =>
                          handleInputChange(user, "username", e.target.value)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {!editingUser(user) ? (
                      user.phonenumber || "N/A"
                    ) : (
                      <input
                        type="text"
                        value={editedUsers[user.id].phonenumber}
                        onChange={(e) =>
                          handleInputChange(user, "phonenumber", e.target.value)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {!editingUser(user) ? (
                      new Date(user.createdAt).toLocaleDateString()
                    ) : (
                      <input
                        type="text"
                        value={new Date(editedUsers[user.id].createdAt).toLocaleDateString()}
                        onChange={(e) =>
                          handleInputChange(user, "createdAt", e.target.value)
                        }
                        disabled
                      />
                    )}
                  </td>
                  {/* <td>
                    <div className="user_controls">
                      <FileText
                        className="kyc user_control"
                        onClick={() => openKycModal(user)}
                      />
                      {!editingUser(user) && !savingUser(user) && (
                        // <Pencil
                        //   className="edit user_control"
                        //   onClick={() => editUser(user)}
                        // />
                      )}
                      {editingUser(user) && !savingUser(user) && (
                        <>
                          <Check
                            className="save_edit user_control"
                            onClick={() => updateUser(user)}
                          />
                          <X
                            className="cancel_edit user_control"
                            onClick={() => cancelEditUser(user)}
                          />
                        </>
                      )}
                      {savingUser(user) && (
                        <Loader2 className="saving_user_loader user_control" />
                      )}
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showKycModal && (
        <div className="kyc_modal">
          <div className="kyc_modal_content">
            <h2>Upload KYC Documents for {selectedUser.firstName} {selectedUser.lastName}</h2>
            <input type="file" onChange={handleKycUpload} />
            <button onClick={closeKycModal}>Close</button>
          </div>
        </div>
      )}
      {showAddUserModal && (
        <div className="add_user_modal">
          <div className="add_user_modal_content">
            <h2>Add New User</h2>
            <form onSubmit={handleSubmit(onAddUser)}>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    First name <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your first name"
                    name="firstName"
                    className={cn({ error: errors.firstName?.type == "required" })}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your first name
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Last name <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your last name"
                    name="lastName"
                    className={cn({ error: errors.lastName?.type == "required" })}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your last name
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    Email <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your email"
                    name="email"
                    className={cn({ error: errors.email?.type == "required" })}
                    {...register("email", { required: true })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your email
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Role <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter user role"
                    name="role"
                    className={cn({ error: errors.role?.type == "required" })}
                    {...register("role", { required: true })}
                  />
                  {errors.role?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the user role
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    Phone Number <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter phone number"
                    name="phone"
                    className={cn({ error: errors.phone?.type == "required" })}
                    {...register("phone", { required: true })}
                  />
                  {errors.phone?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the phone number
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Address <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter address"
                    name="address"
                    className={cn({ error: errors.address?.type == "required" })}
                    {...register("address", { required: true })}
                  />
                  {errors.address?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the address
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="signup_btn_box">
                <button type="submit" className="signup_btn">
                  Add User
                </button>
                <button type="button" className="cancel_btn" onClick={closeAddUserModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientUsers;