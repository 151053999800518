import "./approval.scss";
import { Pencil, Loader2 } from "lucide-react";
import { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApprovalSettings = () => {
  const [settings, setSettings] = useState([
    { id: 1, transactionType: "Bank Transfer", approvers: 2, minAmount: 1000, maxAmount: 10000 },
    { id: 2, transactionType: "Mobile Money", approvers: 3, minAmount: 500, maxAmount: 5000 },
    { id: 3, transactionType: "Cash On Delivery", approvers: 1, minAmount: 2000, maxAmount: 20000 },
  ]);
  const [editedSetting, setEditedSetting] = useState(null);
  const [isSavingSetting, setIsSavingSetting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const openEditModal = (setting) => {
    setEditedSetting({ ...setting });
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setEditedSetting(null);
    setShowEditModal(false);
  };

  const handleInputChange = (field, value) => {
    setEditedSetting({
      ...editedSetting,
      [field]: value,
    });
  };

  const updateSetting = () => {
    if (editedSetting.approvers > 3) {
      toast.error("Approvers cannot exceed 3.");
      return;
    }

    setIsSavingSetting(true);
    // Simulate saving setting
    setTimeout(() => {
      setSettings(settings.map((s) => (s.id === editedSetting.id ? editedSetting : s)));
      setIsSavingSetting(false);
      closeEditModal();
      toast.success("Setting updated successfully!");
    }, 1000);
  };

  return (
    <div className="approval_settings_page">
      <div className="heading">
        <div className="title">Approval Settings</div>
      </div>
      <div className="settings_table_container">
        <table className="settings_table">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction Type</th>
              <th>Approvers</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {settings.map((setting, index) => (
              <tr key={setting.id}>
                <td>{index + 1}</td>
                <td>{setting.transactionType}</td>
                <td>{setting.approvers}</td>
                <td>
                  <div className="setting_controls">
                    <Pencil
                      className="edit setting_control"
                      onClick={() => openEditModal(setting)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showEditModal && (
        <div className="edit_modal">
          <div className="edit_modal_content">
            <h2>Edit Setting</h2>
            <div className="form_group">
              <label>Transaction Type</label>
              <input
                type="text"
                value={editedSetting.transactionType}
                onChange={(e) => handleInputChange("transactionType", e.target.value)}
                readOnly
              />
            </div>
            <div className="form_group">
              <label>Number of Approvers</label>
              <select
                value={editedSetting.approvers}
                onChange={(e) => handleInputChange("approvers", e.target.value)}
              >
                <option value="1">1 Approver</option>
                <option value="2">2 Approvers</option>
                <option value="3">3 Approvers</option>
              </select>
            </div>
            <div className="modal_buttons">
              <button onClick={updateSetting} disabled={isSavingSetting}>
                {isSavingSetting ? (
                  <>
                    <Loader2 className="icon" /> Saving...
                  </>
                ) : (
                  "Save"
                )}
              </button>
              <button onClick={closeEditModal} disabled={isSavingSetting}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ApprovalSettings;